import PaginationRequest from "@/models/general/PaginationRequest";
import GeneralFiltersTable from "@/models/general/Paginations/Filters/GeneralFiltersTable";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from '../../../../commons/MutationsTable';
import store from '@/store';
import Modules from "@/store/enums/Modules";
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import { Actions } from "@/store/commons/ActionsTable";
import TablePaginationRequest from "@/models/general/TablePaginationRequest";
import TableCashiersState from "../../models/TableCashRegisterState";
import CashierRow from '@/models/cashiers/CashierRow';
import CashRegistersCatalogService from "@/core/services/cashregister/CashRegistersCatalogService";
import CashRegistersRow from "@/models/cash-register/catalog/CashRegistersRow";

@Module({dynamic: true, store, namespaced: true, name: Modules.TableCashRegistersModule})
class TableCashRegisterModule extends VuexModule implements TableCashiersState {

    records = [] as CashRegistersRow[];
    totalRecords = 0;
    filters = {
        filterName: ''
    } as GeneralFiltersTable;
    pagination = {
        currentPage: 1
        , pageSize: 10 //default
    } as PaginationRequest;
    loading = false;

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: CashRegistersRow[]){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: unknown){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters, true);

    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

    /**
     * Se obtienen los datos de la tabla mediante la paginación
     * @param paginator paginador de las notas
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        
        const paginator = {
            active: this.filters.filterActive
            , name: this.filters.filterName
            , ...(this.pagination)
        } as TablePaginationRequest

         const resp = (await service.getTableInfo(paginator)
         .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ??
             {
                 data: [] as CashRegistersRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
             }
 
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalPages);
    }

    /**
       * Realiza un busqueda con la nueva paginación
       * @param pagination paginación de la tabla
       */
     @Action
    async [Actions.CHANGE_PAGE](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION, pagination);
          this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    /**
     * Filtros que se aplican y despues se hace la busqueda de los datos
     * @param filters filtros nuevos
     */
    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.commit(Mutations.SET_PAGINATION, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
      });
        this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    /**
     * Cambia el estatus de la entrada
     * @param id identificador de la entrada
     * @param newStatus nuevo estatus a asignarle
     */
    @Action
    async [Actions.CHANGE_STATUS](payload: { id:string, newStatus: boolean}){
        
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.changeStatus(payload.id, payload.newStatus)
        .then(() => this.context.dispatch(Actions.SEARCH_TABLE_DATA))
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false));
        
    }
    
}

/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
const service = new CashRegistersCatalogService();
 

export default getModule(TableCashRegisterModule);